<template lang="pug">
footer.footer.s-swatch-black(:class='{minimal}')
  .s-wrapper.s-overflow--hidden
    .quoteSection
      .grid-cols--3-spacing--m.s-padding-top--l.s-padding-bottom
        .col.logo.s-align-mobile--center
          div
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50"><path d="M14.5 22.1H7.9c-.8 0-1.4-.1-1.8-.3-.4-.2-.6-.8-.6-1.7v-1.5c0-.8.1-1.4.4-1.7.3-.3.9-.5 1.9-.5h11.9v-3.7c-1-.4-2.8-.6-5.4-.8-2.7-.2-5.2-.3-7.7-.3-4.4 0-6.6 2.2-6.6 6.6v2.2c0 2.4.6 4.1 1.7 5.1S4.6 27 6.9 27h6.5c.8 0 1.3.2 1.6.5.3.3.5.8.5 1.5v1.6c0 1.3-.7 2-2.1 2H1.1v3.6c.8.4 2.7.6 5.7.9s5.7.3 8.1.3c4.1 0 6.2-2.2 6.2-6.5v-2.5c0-1.9-.5-3.5-1.6-4.6-1.2-1.1-2.8-1.7-5-1.7zM38.2 11.6c-1.4 0-2.9.3-4.6 1-1.6.6-2.9 1.3-3.8 2L28.7 12h-4.4v38.3h5.5V35.9c1.2.5 2.6.9 4.2 1.2 1.6.3 3 .5 4.1.5 2.6 0 4.6-.9 6-2.6 1.6-2 2.3-5.5 2.3-10.6.1-8.5-2.7-12.8-8.2-12.8zm1 20.8c-.5.4-1.2.6-2.1.6-2.6 0-5-.4-7.2-1.1V18.3c2.3-1.2 4.7-1.8 7.2-1.8 1.3 0 2.2.6 2.9 1.9.6 1.3 1 3.3 1 6.2 0 4.3-.6 6.9-1.8 7.8zM71.2 20.8c0-2.8-.4-4.9-1.2-6.1-.4-.7-.9-1.2-1.4-1.6s-1.2-.7-2.1-.9c-.9-.2-1.7-.3-2.6-.4-.8-.1-2.2-.1-4-.1-1.8 0-3.4.2-4.9.6-1.4.4-2.6 1.3-3.6 2.5-1.2 1.7-1.9 4.9-1.9 9.7 0 4.8.6 8.2 1.8 10.1 1.2 1.9 3.4 2.9 6.6 2.9 5.8 0 10-.4 12.6-1.3v-3.9c-2.2.4-4.7.5-7.6.5s-4.5-.1-5.1-.2-1.1-.4-1.6-.8c-.8-.7-1.2-2.3-1.2-4.8h9c4.9 0 7.2-2.1 7.2-6.2zm-15.9 1.9c0-1.1 0-2.1.1-3 .1-1 .5-1.8 1.2-2.6.5-.6 2-.9 4.5-.9 2 0 3.2.3 3.8.8.5.5.8 1.7.8 3.6 0 1.4-.8 2.1-2.4 2.1h-8zM80.6 31.3c-.4-.7-.6-1.9-.6-3.7V-.2h-5.5v28.4c0 3.2.6 5.5 1.7 6.9 1.1 1.4 2.7 2.1 4.8 2.1h5v-3.5c-.2 0-1.4-.3-3.6-1-.8-.2-1.5-.7-1.8-1.4zM96.4 32.7c-.8-.3-1.4-.7-1.8-1.4-.4-.7-.6-1.9-.6-3.7V-.2h-5.5v28.4c0 3.2.6 5.5 1.7 6.9 1.1 1.4 2.7 2.1 4.8 2.1h5.1v-3.5c-.3 0-1.5-.3-3.7-1z"/></svg>
          // .s-comment.s-text--caps-size--3xs.s-margin-top--xs &copy; Spell 2021
        .quoteBlock.col.s-text-align--center
          .quote.s-text--italic.textSilver.s-margin-bottom--s.s-relative
            span.icon-double-quotes-l
            | We see Spell as a Formula 1 pit stop for payment businesses, where they can update their vehicle at light-speed and get back on track to win the race.
            span.icon-double-quotes-r
          .author.s-text--semibold-size--xxs--caps.textSilver &mdash; Jevgenijs Novickis, Spell CEO
        .col.s-text-align--right.s-align-mobile--center
          .tag.s-text-size--4xl--light.s-margin-bottom--s.s-align-mobile--center #spelltosell
      hr.s-margin--none
    .s-flex-direction--column-items--center-content--center.s-padding--l.s-padding-bottom.s-items-spacingVertical--s
      logoEDRF.logo.s-blockWidth--20.s-expand-phone
      .s-text--center-size--xxs.s-comment Project No. JU-PI-2021/18 - 1.2.1.2/16/I/001
    .s-flex-items--center-content--center--justified-stackable-mobile--s--reverse.s-padding-y--s.s-relative
      nav.s-flex-items--center.s-items-spacing--s.s-text-size--3xs--caps.s-align-mobile--center
        .s-comment &copy; Spell 2021
        router-link.s-text--semibold.s-comment(to='/cookies') Cookie Policy
      .s-expand
        // TrustBox widget - Micro Review Count
        .s-move--center.trustpilot-widget(data-locale='en-US' data-template-id='5419b6a8b0d04a076446a9ad' data-businessunit-id='601cf154c26b710001dbae00' data-style-height='24px' data-theme='dark')
          a(href='https://www.trustpilot.com/review/ourspell.com' target='_blank' rel='noopener') Trustpilot
        // End TrustBox widget
      .s-flex-items--center-content--end.s-align-mobile--center
        a.linkedin.s-button.s-shape-pill.s-flex-items--center-content--center.s-text--caps-size--3xs(href='https://www.linkedin.com/company/spelltosell')
          span.icon-linkedin-box-fill.s-text-size--l.s-margin-right--xxs
          span Follow
</template>

<script>
import logoEDRF from '@/components/logos/logoEDRF'
export default {
  name: 'Main Footer',
  components: {
    logoEDRF
  },
  props: {
    minimal: { type: Boolean }
  }
}
</script>
